import React, { useState } from "react";
import { Navbar, Nav, Container, NavDropdown } from "react-bootstrap";
import logo from "../../assets/img/Nomad-e_simbolo_white.png";
import "../../assets/css/Navbar.css";

export default function Header() {
  return (
    // <Navbar className="nav-color" fixed="top" variant="dark" expand="lg" >
    //   <Container className="position-relative">
    //     <Navbar.Brand href="/">
    //       <img
    //         src={logo}
    //         width="40"
    //         height="40"
    //         className="d-inline-block align-top"
    //         alt="Nomad-e logo"
    //       />
    //     </Navbar.Brand>
    //     <Navbar.Toggle aria-controls="basic-navbar-nav" />
    //     <Navbar.Collapse id="basic-navbar-nav">
    //       <Nav className="position-absolute end-0">
    //         <Nav.Link href="/">HOME</Nav.Link>
    //         <Nav.Link href="/aboutus">ABOUT US</Nav.Link>
    //         <Nav.Link href="/blog">BLOG</Nav.Link>
    //         <Nav.Link href="/research">RESEARCH</Nav.Link>
    //         <Nav.Link href="/contact">CONTACT</Nav.Link>
    //       </Nav>
    //     </Navbar.Collapse>
    //   </Container>
    // </Navbar>

    <Navbar className="nav-color" fixed="top" variant="dark" expand="lg">
      <Container className="position-relative">
        <Navbar.Brand href="/">
          <img
            src={logo}
            width="40"
            height="40"
            className="d-inline-block align-top"
            alt="Nomad-e logo"
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link href="/">HOME</Nav.Link>
            {/* <Nav.Link href="/aboutus">ABOUT US</Nav.Link> */}
            <Nav.Link href="/blog">BLOG</Nav.Link>
            <Nav.Link href="/research">RESEARCH</Nav.Link>
            <Nav.Link href="/contact">CONTACT</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
