import React, { useEffect, useState } from "react";
import ResearchDetailSection from "../section/ResearchDetailSection";
import { useParams } from "react-router-dom";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import ShortenText from "../../utils/ShortenText.js";
import ToText from "../../utils/ToText.js";
import { Card, Container } from "react-bootstrap";

export default function ResearchDetail() {
  const [research, setResearch] = useState({});
  const citation_id = useParams();

  console.log(citation_id);
  const googleScholarURL =
    "https://nomade-research.herokuapp.com/researches/" +
    citation_id.citation_id;

  useEffect(() => {
    getResearchDetail();
  }, [citation_id]);

  useEffect(() => {
    console.log("research", research);
  }, [research]);

  async function getResearchDetail() {
    const researchData = await fetch(googleScholarURL).then((response) =>
      response.json()
    );
    setResearch(researchData.citation);
  }

  return (
    <>
      <div className="container">
        <section className="px-5 py-4 mt-5">
          {research.title != (undefined || null) ? (
            <ResearchDetailSection {...research} />
          ) : (
            <>Carregando...</>
          )}
        </section>
      </div>
    </>
  );
}
