import React, { useState } from "react";
import ReactCardFlip from "react-card-flip";
import { Card } from "react-bootstrap";

export default function FlipCard(props) {
  const [isFlipped, setIsFlipped] = useState(false);

  function handleClick() {
    setIsFlipped(!isFlipped);
  }

  return (
    <>
      <ReactCardFlip isFlipped={isFlipped} flipDirection="vertical">
        <Card border="primary" style={{ height: "18rem" }} onClick={() => handleClick()}>
          <Card.Body className="text-center flex-star-middle">
            <div >
            <Card.Img
              src={props.img}
              style={{ height: "12rem", width: "12rem", margin: "auto" }}
            />
              <h5>
                {props.title}
                </h5>
              </div>
          </Card.Body>
        </Card>

        <Card
          border="primary"
          className="h-auto"
          onClick={() => handleClick()}
          style={{ height: "20rem" }}
        >
          <Card.Body >
            <Card.Title className="mt-2 mb-2">{props.title}</Card.Title>
            <Card.Text className="mt-2 mb-2 h-auto">
              {props.text} <br /> {/* <a href={props.link}>{props.title}</a> */}
              <br /><p style={{color:"#293a8c",textAlign:"center"}}>Its Comming in Hot ...</p>
            </Card.Text>
          </Card.Body>
        </Card>
      </ReactCardFlip>
    </>
  );
}
