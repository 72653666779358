import React, { useEffect, useState } from "react";
import StorieDetails from "../section/StorieDetails";
import { useParams } from "react-router-dom";

export default function Storie() {
  const [storie, setStorie] = useState({});
  const id = useParams();
  const urlGuid = "https://medium.com/p/" + id.guid;

  const mediumURL =
    "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@nomad-e";

  useEffect(() => {
    getStorie();
  }, [urlGuid]);

  useEffect(() => {
    console.log(storie);
  }, [storie]);

  async function getStorie() {
    const data = await fetch(mediumURL).then((res) => res.json());

    // create two-dimensional array with 3 elements per inner array
    const avatar = data.feed.image;
    const profileLink = data.feed.link;
    const res = data.items; //This is an array with the content. No feed, no info about author etc..
    const blog = res.filter((item) => item.guid === urlGuid);

    //const itemRows = [];
    blog.forEach((item, i) => {
      item["avatar"] = avatar; // push avatar inside the json
      item["profilelink"] = profileLink; // push profile link inside the JSON
    });

    setStorie(blog[0]);
  }

  // console.log("Storie", storie);

  //https://medium.com/p/

  return (
    <>
      <div className="container">
        <section className="px-5 py-4 mt-5">
          {/* <h1>Nomad-e's Blog</h1> */}
          {storie.avatar != (undefined || null) ? (
            <StorieDetails {...storie} />
          ) : (
            <>Carregando...</>
          )}
        </section>
      </div>
    </>
  );
}
