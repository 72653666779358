import React, { Component, useEffect, useState } from "react";
import "./../../assets/css/ResearchHero.css";
/* import "./../../assets/css/Button.css"; */
import logo from "./../../assets/img/research-hero.jpg";
import video from "./../../assets/img/research-hero.jpg";
import { Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { BsFileEarmarkText} from "react-icons/bs"
import Typed from "react-typed";

export default function HeroResearch() {
  // Text Animation
   const animation = () => {
   /*  const text = document.querySelector(".sec-text"); */
   const text = document.getElementById("animation");
     console.log("imprimendo o text para aniamacao  ", text);
   const textLoad = () => {
      setTimeout(() => {
       text.textContent = "Publications";
       }, 0);

       setTimeout(() => {
        
         text.textContent = "Cientific";
       }, 2000);

     setTimeout(() => {
       }, 4000);

       setTimeout(() => {
       text.textContent = "Articles";
      }, 6000);
     };

   textLoad();
     setInterval(textLoad, 16000);
   }

   useEffect(() => {
     animation();
 }, [])

  return (
 
    <div className="hero-research mt-5 pt-5 ">
      {/* <video src={video} autoPlay loop muted /> */}
      {/* <img
        className="mt-5 pt-2"
        src={logo}
        id="hero-logo"
        alt="Nomad-e Empowering Humans"
        width="100%"
      /> */}
      <section className="py-5 w-100 m-5">
        <div className="container px-5 ">
          <div className=" justify-content-start w-100 ">
            <div className="" data-aos="fade-up" data-aos-delay="100">
              <div className="container-text">
                  <FontAwesomeIcon icon="fa-regular fa-file-lines" />
                <h1 >
                  <BsFileEarmarkText />
                  {/* <span class="text first-text fw-bolder mb-3">Research</span> */}
                <Typed
      strings={[
        "Research"
     /*    "I Love Software Development",
        "I Love All My Subscribers", */
      ]}
      typeSpeed={150}
      backSpeed={100}
      loop
      />
      </h1>
     {/*  <p id="animation" class=".sec-text"></p> */}
               
              </div>
         {/*    <h1 id="animation" class=".sec-text"></h1> */}
            </div>

          </div>
        </div>
      </section >
    </div>
  );
}

