import { useState } from 'react';
import { MdLocationPin, MdEmail, MdCall } from "react-icons/md";
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';
import Button from "react-bootstrap/Button";

function Contact() {
  const [inputs, setInputs] = useState({});
  const [name, setName] = useState(undefined);
  const [email, setEmail] = useState(undefined);
  const [description, setDescription] = useState(undefined);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    const name = event.target.id;
    const value = event.target.value;
    setInputs(values => ({ ...values, [name]: value }))
  }

   const handleSubmit = async (event) => {
    event.preventDefault();
    try{
    const data = new URLSearchParams();
    const formElement = document.querySelector("#form-data")
    for (const pair of new FormData(formElement)) {
        data.append(pair[0],pair[1]);
    }
    const url = 'https://crm.zoho.com/crm/WebToLeadForm';
    const response = await fetch(url, { method: 'POST', body: data });
    if(response.status >= 200 && response.status <=399){
      setLoading(false)
      setEmail("")
      setName("")
      setDescription("")
      setSuccess(true)
      setTimeout(()=>setSuccess(false), 6000)
    }else{
      console.log("ERROR STATUS: ",response.status)
      setError(true)
    }
  }catch(err){
    console.log(err)
    setLoading(false)
    setEmail("")
    setError(true)
    setTimeout(()=>setError(false), 3000)
  }
  }

  return (
    <>
      <div className="conteiner">
        {/* ======= Breadcrumbs ======= */}
        <div class="breadcrumbs d-flex align-items-center ">
          <div class="container position-relative d-flex flex-column align-items-center">

            <h2>Contact</h2>
            {/* <ol>
          <li><a href="index.html">HOME</a></li>
          <li>Contact</li>
        </ol> */}

          </div>
        </div>{/* <!-- End Breadcrumbs --> */}

        {/*  ======= Contact Section =======  */}
        <section id="contact" className="contact px-5 py-4 mt-5">
          <div class="container position-relative" data-aos="fade-up">

            <div class="row gy-4 d-flex justify-content-end">

              <div class="col-lg-5" data-aos="fade-up" data-aos-delay="100">

                <div class="info-item d-flex">
                  <i class="bi bi-geo-alt flex-shrink-0"></i>
                  <div>
                    <h4><MdLocationPin />
                      {" "}Location</h4>
                    <h6>Ubimedical</h6><p>
                      Estrada Municipal 506 , Covilhã, Castelo Branco 6200-284 , PT</p>
                  </div>
                </div>{/* <!-- End Info Item --> */}

                <div class="info-item d-flex">
                  <i class="bi bi-envelope flex-shrink-0"></i>
                  <div>
                    <h4><MdEmail />{" "}Email </h4>
                    <p>info@nomad-e.eu</p>
                  </div>
                </div>{/* <!-- End Info Item --> */}

                <div class="info-item d-flex">
                  <i class="bi bi-phone flex-shrink-0"></i>
                  <div>
                    <h4><MdCall />{" "}Call</h4>
                    <p>+351 928034842</p>
                  </div>
                </div>
                {/* <!-- End Info Item -->*/}
              </div>

              <div class="col-lg-6" data-aos="fade-up" data-aos-delay="250">

                <form id="form-data" onSubmit={handleSubmit}>      
                  <div class="form-group mt-3">
                  <input type='text' style={{display:"none"}} name='xnQsjsdp' id="xnQsjsdp" value='dd1540683c2ab91fdf828a3f9d381acd8a834197a2ee4db1307c2dcda5f0afb7' /> 
                  <input type='hidden' name='zc_gad' id='zc_gad' value=''></input> 
                  <input  input type='text' style={{display:"none"}} name='xmIwtLD' id="xmIwtLD" value='8b8f4b87d121501770423c3d6ec5f81550dc52d5fb5a07efc35408ce74ff968b' />
                  <input type='text'  style={{display:"none"}} name='actionType' id='actionType' value='TGVhZHM=' />
                  <input type='text' style={{display:"none"}} name='returnURL' id='returnURL' value='https&#x3a;&#x2f;&#x2f;nomad-e.eu&#x2f;' /> 
                    
                  <input type="text" name="Last Name" class="form-control" id="Last Name" placeholder="Your Name " required onChange={handleChange} value={name}/>
                  </div>
                  <div class=" form-group mt-3 ">
                    <input type="email" class="form-control" name="Email" id="Email" placeholder="Your Email " required onChange={handleChange} value={email}/>
                  </div>

                  {/*  <div class="form-group mt-3">
                <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" required />
              </div> */}
                  <div class="form-group mt-3">
                    <textarea class="form-control" name="Description" rows="5" placeholder="Message" id='Description' required onChange={handleChange} value={description}></textarea>
                  </div>
                  {/* <div class="my-3">
                    <div class="loading">Loading</div>
                <div class="error-message"></div>
                <div class="sent-message">Your message has been sent. Thank you!</div>
                  </div> */}

                        <br />
                        {success 
                    ? <Alert variant="info" onClose={() => setSuccess(false)} dismissible>Thank you for your contact, you will receive a confirmation email from us shortly.</Alert>
                    : <></>
                  }
                  {error 
                  ?<Alert variant="warning" onClose={() => setError(false)} dismissible>Oups! An error occured. Appologies for the inconvenience. Please try again.</Alert>
                  :<></>
                }
                        <br />
                  {!loading
                      ?<div class="text-center"><button className="button mt-0 m-auto m-2 text-center" type="submit">Send Message</button></div>
                      :<Button variant="primary mt-0 mb-0 m-4" disabled>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Loading...
                    </Button>}

                  {/* <div class="text-center"><button type="submit">Send Message</button></div> */}
                </form>

              </div>{/* <!-- End Contact Form --> */}

            </div>

          </div>
          {/* <!-- End Contact Section --> */}
        </section>

      </div>
    </>
  );
}

export default Contact;