import React, { useEffect } from "react";
/* import { parse } from "rss-to-json"; */
import BlogPost from "../section/BlogPost.js";
export default function Blog() {
  /*   async function getBlogPosts(){
    var rss = await parse('https://medium.com/feed/@nomad-e');
 
    console.log(JSON.stringify(rss, null, 3));

  }
  
  useEffect(async () => {
    await getBlogPosts();
  }, []);
 */
  return (
    <>
      <div className="container ">
        <section className="px-5 py-4 mt-5">
          <center>
            <h1 className="mt-3 mb-3">Nomad-e's Blog</h1>
          </center>
          <BlogPost />
        </section>
      </div>
    </>
  );
}
