import React from "react";
import Card from "react-bootstrap/Card";
import ShortenText from "../../utils/ShortenText.js";
import ToText from "../../utils/ToText.js";
import { faUser, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import "../../assets/css/Button.css";
import { useNavigate } from "react-router-dom";

export default function ResearchCard(props) {
  const navigate = useNavigate();

  function ResearchDetail(citation_id) {
    //  const url = guid.slice(21, guid.length);
    navigate(`/research/${citation_id}`);
  }

  return (
    <div small className="card-post card-post--1 mt-4">
      <Card.Body>
        <span className="text-dark">
          <FontAwesomeIcon icon={faUser} /> {props.authors}{" "}
          {props.year ? (
            <span className="text-muted">
              <FontAwesomeIcon icon={faCalendarAlt} /> {props.year}
            </span>
          ) : (
            <></>
          )}
        </span>

        <h5 className="card-title">
          <Button
            variant="link"
            className="p-0"
            size="lg"
            onClick={() => ResearchDetail(props.citation_id)}
          >
            {ShortenText(props.title, 0, 50)}
          </Button>
        </h5>
      </Card.Body>
    </div>
  );
}
