import React from "react";
import imagem from "../../assets/img/HowWeDo.jpg";

export default function HowWeDo() {
  return (
    <section
      className="py-5 d-flex"
      style={{ color: "black", backgroundColor: "#f5f5f5" }}
    >
      <div class="container px-5 my-5">
        <div class="row gx-5 align-items-center">
          <div
            class="col-lg-6 order-first order-lg-last"
            data-aos="fade-up"
            data-aos-delay="100"
          >
            <img
              class="img-fluid rounded mb-5 mb-lg-0"
              src={imagem}
              style={{height:"312px", width:"700px"}}
              alt="how we do"
            />
          </div>
          <div class="col-lg-6" data-aos="fade-up" data-aos-delay="100">
            <h2 className="fw-bolder"> How we do</h2>
            <p class="fw-normal text-muted mt-15">
              We believe in the interoperability of all processes through
              design, agility, and well-thought technology. Our
              Multidisciplinary Team is ready to design, develop and evolve your
              digital product, service, or experience.
            </p>

            {/*   <a class="btn-get-started" href="como-fazemos.html">
              {" "}
              Read More
              <i class="bi bi-arrow-right"></i>
            </a> */}
          </div>
        </div>
      </div>
    </section>
  );
}
