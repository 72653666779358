import Card from "react-bootstrap/Card";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Button from "react-bootstrap/Button";
import HealtTech from "./../../assets/img/HealtTech.png";
import EduTech from "./../../assets/img/EduTech.png";
import PharmaTech from "./../../assets/img/PharmaTech.png";
import GreenTech from "./../../assets/img/GreenTech.png";
import "../../assets/css/Services.css";

function OurService() {
  return (
    <section class="px-5 py-5 bg-light">
      <center>
        <h1>Our Services</h1>
      </center>
      <div class="container">
        <Row xs={1} md={2} className="g-4">
          <Col>
            <Card.Img className="img-center" variant="top" src={HealtTech} />
            <Card.Body>
              <Card.Title>Healt Tech</Card.Title>
              <Card.Text>
                By applying our interoperability framework we manage to connect
                all things that matter to our customers in their clinical
                practice. We develop and customize a wide range of health
                products that, in one way or another, are used to diagnose,
                monitor or help treat all diseases and conditions that affect
                human beings.
              </Card.Text>
            </Card.Body>
          </Col>
          <Col>
            <Card.Img className="img-center" variant="top" src={PharmaTech} />
            <Card.Body>
              <Card.Title>Pharm Tech</Card.Title>
              <Card.Text>
                We develop features to help Patient medication management in
                out-patient and in-patient clinical contexts, thus, pushing
                healthcare towards a patient focused approach and evidence-based
                clinical practices.
              </Card.Text>
            </Card.Body>
          </Col>
          <Col>
            <Card.Img className="img-center" variant="top" src={GreenTech} />
            <Card.Body>
              <Card.Title>Green Tech</Card.Title>
              <Card.Text>
                The concept of Green Tech refers to all technological advances
                that are carried out in a sustainable way. In other words, that
                it is carried out without harming the environment or human
                beings.
              </Card.Text>
            </Card.Body>
          </Col>
          <Col>
            <Card.Img className="img-center" variant="top" src={EduTech} />
            <Card.Body>
              <Card.Title>Edu Tech</Card.Title>
              <Card.Text>
                Educational technology or EdTech refers to an area of
                ​​technology dedicated to the development and application of
                tools (including software, hardware and processes) intended for
                higher education.
              </Card.Text>
            </Card.Body>
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default OurService;
