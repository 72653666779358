import React, { useEffect } from "react";


export default function Privacy() {
  return (
    <>
      <div className="container ">
        <section className="px-5 py-4 mt-5">
        <header>
        <div class="container">
            <div class="row pt-5 ">
                <div class="col ">
                   {/*  <label class="text-muted">Seleccione a jurisdição:</label>
                    <div>
                        <div class=" form-check form-check-inline fw-lighter">
                            <input class="form-check-input" type="radio" id="inlineRadio1" value="option1" />
                            <label class="fs-6 form-check-label" for="inlineRadio1">Portugal</label>
                        </div>
                        <div class="form-check form-check-inline fw-lighter">
                            <input class="form-check-input" type="radio" id="inlineRadio2" value="option2" />
                            <label class="fs-6 form-check-label" for="inlineRadio2">Brasil</label>
                        </div>
                    </div> */}
                </div>
                <div class="col">
                    <p class="fw-lighter">Última atualização: 06 de fevereiro de 2022</p>
                </div>
            </div>
        </div>

        <h2 class="fs-1 fw-bolder pt-5 text-muted text-center">Política de privacidad de Nomad-e</h2>
        {/* <!--<img src="assets/img/privacity.png" class="img-fluid rounded" alt="...">--> */}

    </header>
    <section class="px-5">
        <div class="px-5">
            <div class="card-body mb-4 lh-lg fw-lighter">
                <p>
                    Esta Política de Privacidade descreve Nossas políticas e procedimentos sobre a coleta, uso e
                    divulgação de Suas informações quando Você usa o Serviço e informa sobre Seus direitos de
                    privacidade e como a lei protege Você. </p>

                <p>Usamos seus dados pessoais para fornecer e melhorar o serviço. Ao usar o Serviço, você concorda com
                    a coleta e uso de informações de acordo com esta Política de Privacidade. Esta Política de
                    Privacidade foi criada com a ajuda do Modelo de Política de Privacidade.</p>

                <h2 class="card-title text-muted" id="interpretacao">Interpretação e Definições</h2>
                <h3 class="text-muted">Interpretação</h3>
                <p>As palavras cuja letra inicial é maiúscula têm significados definidos nas seguintes condições. As
                    seguintes definições terão o mesmo significado, independentemente de aparecerem no singular ou no
                    plural.</p>

                <h3 class="text-muted">Definições</h3>
                <p>Para efeitos desta Política de Privacidade:</p>
                <ul>

                    <li>
                        <p><strong class="text-muted" class="text-muted">Conta</strong> significa uma conta exclusiva
                            criada para você acessar nosso Serviço ou partes de nosso
                            Serviço.</p>
                    </li>

                    <li>
                        <p><strong class="text-muted" class="text-muted">Empresa</strong> (referida como "a Empresa",
                            "Nós", "Nós" ou "Nosso" neste Contrato) refere-se a Nomad-e
                            Digital, Ubimedical Covilha 6200100.</p>
                    </li>

                    <li>
                        <p><strong class="text-muted">Cookies</strong> são pequenos arquivos que são colocados em seu
                            computador, dispositivo móvel ou qualquer
                            outro dispositivo por um site, contendo os detalhes de seu histórico de navegação nesse site
                            entre
                            seus diversos usos.</p>
                    </li>

                    <li>
                        <p><strong class="text-muted">País</strong> refere-se a: Portugal</p>
                    </li>

                    <li>
                        <p><strong class="text-muted">Dispositivo</strong> significa qualquer dispositivo que possa
                            acessar o Serviço, como um computador, um
                            celular ou um tablet digital.</p>
                    </li>

                    <li>
                        <p><strong class="text-muted">Dados</strong> Pessoais são quaisquer informações relacionadas a
                            um indivíduo identificado ou identificável.</p>
                    </li>

                    <li>
                        <p><strong class="text-muted">Serviço</strong> refere-se ao Site.</p>
                    </li>

                    <li>
                        <p><strong class="text-muted">Prestador</strong> de Serviços significa qualquer pessoa física ou
                            jurídica que processe os dados em nome da
                            Empresa. Refere-se a empresas terceirizadas ou indivíduos empregados pela Empresa para
                            facilitar o
                            Serviço, fornecer o Serviço em nome da Empresa, realizar serviços relacionados ao Serviço ou
                            ajudar
                            a Empresa a analisar como o Serviço é usado.</p>
                    </li>

                    <li>
                        <p><strong class="text-muted">Serviço</strong> de mídia social de terceiros refere-se a qualquer
                            site ou site de rede social por meio do
                            qual um usuário possa fazer login ou criar uma conta para usar o serviço.</p>
                    </li>

                    <li>
                        <p><strong class="text-muted">Dados</strong> de uso referem-se a dados coletados
                            automaticamente, gerados pelo uso do Serviço ou da própria
                            infraestrutura do Serviço (por exemplo, a duração de uma visita à página).</p>
                    </li>

                    <li>
                        <p><strong class="text-muted ">Site</strong> refere-se ao Nomad-e, acessível em <a
                                href="https://nomad-e.eu" class="text-decoration-none">https://nomad-e.eu</a>
                        </p>
                    </li>

                    <li>
                        <p><strong class="text-muted">Você</strong> significa o indivíduo acessando ou usando o Serviço,
                            ou a empresa ou outra entidade legal em
                            nome da qual tal indivíduo está acessando ou usando o Serviço, conforme aplicável.</p>
                    </li>
                </ul>

                <h2 class="card-title text-muted" id="coletados">Coletando e usando seus dados pessoais</h2>
                <h3 class="text-muted">Tipos de dados coletados</h3>
                <h4 class="text-muted">Dados pessoais</h4>
                <p>Ao usar nosso Serviço, podemos solicitar que você nos forneça determinadas informações de
                    identificação pessoal que podem ser usadas para contatá-lo ou identificá-lo. As informações de
                    identificação pessoal podem incluir, mas não se limitam a:</p>
                <ul>
                    <li>
                        <p>Endereço de e-mail</p>
                    </li>

                    <li>
                        <p>Nome e sobrenome</p>
                    </li>

                    <li>
                        <p>Dados de uso</p>
                    </li>
                </ul>

                <h4 class="text-muted">Dados de uso</h4>
                <p>Os Dados de Uso são coletados automaticamente ao usar o Serviço.</p>

                <p>Os Dados de Uso podem incluir informações como o endereço do protocolo de Internet do seu
                    dispositivo (por exemplo, endereço IP), tipo de navegador, versão do navegador, as páginas do nosso
                    Serviço que você visita, a hora e a data da sua visita, o tempo gasto nessas páginas, dispositivo
                    exclusivo identificadores e outros dados de diagnóstico.</p>

                <p>Quando você acessa o Serviço por ou por meio de um dispositivo móvel, podemos coletar determinadas
                    informações automaticamente, incluindo, mas não se limitando ao tipo de dispositivo móvel que você
                    usa, o ID exclusivo do seu dispositivo móvel, o endereço IP do seu dispositivo móvel, o seu sistema
                    operacional, o tipo de navegador de Internet móvel que você usa, identificadores exclusivos de
                    dispositivo e outros dados de diagnóstico.</p>

                <p>Também podemos coletar informações que seu navegador envia sempre que você visita nosso Serviço ou
                    quando acessa o Serviço por ou por meio de um dispositivo móvel.</p>

                <h2 class="card-title text-muted" id="interpretacao">Informações de serviços de mídia social de
                    terceiros</h2>
                <p>A Empresa permite que você crie uma conta e faça login para usar o Serviço por meio dos seguintes
                    Serviços de mídia social de terceiros:</p>
                <ul>
                    <li>
                        <p>Google</p>
                    </li>
                    <li>
                        <p>Facebook</p>
                    </li>
                    <li>
                        <p>Twitter</p>
                    </li>
                </ul>
                <p>Se você decidir se registrar ou nos conceder acesso a um serviço de mídia social de terceiros,
                    podemos coletar dados pessoais que já estejam associados à conta do seu serviço de mídia social de
                    terceiros, como seu nome, seu endereço de e-mail, suas atividades ou Sua lista de contatos associada
                    a essa conta.</p>

                <p>Você também pode ter a opção de compartilhar informações adicionais com a Empresa por meio da conta
                    do seu serviço de mídia social de terceiros. Se você optar por fornecer tais informações e Dados
                    Pessoais, durante o registro ou de outra forma, você estará dando à Empresa permissão para usá-los,
                    compartilhá-los e armazená-los de maneira consistente com esta Política de Privacidade.</p>

                <h2 class="card-title text-muted" id="rastreamento">Tecnologias de rastreamento e cookies</h2>
                <p>Usamos cookies e tecnologias de rastreamento semelhantes para rastrear a atividade em nosso serviço
                    e armazenar determinadas informações. As tecnologias de rastreamento usadas são beacons, tags e
                    scripts para coletar e rastrear informações e para melhorar e analisar nosso serviço. As tecnologias
                    que usamos podem incluir:</p>
                <ul>
                    <li>
                        <p><strong class="text-muted ">Cookies ou Cookies do navegador.</strong> Um cookie é um pequeno
                            arquivo colocado em seu dispositivo. Você
                            pode instruir seu navegador a recusar todos os cookies ou a indicar quando um cookie está
                            sendo
                            enviado. No entanto, se você não aceitar cookies, talvez não consiga usar algumas partes do
                            nosso
                            serviço. A menos que você tenha ajustado a configuração do seu navegador para que ele recuse
                            cookies, nosso serviço pode usar cookies.</p></li>
                    <li>
                        <p><strong class="text-muted ">Biscoitos Flash.</strong> Certos recursos de nosso Serviço podem
                            usar objetos armazenados localmente (ou
                            Flash Cookies) para coletar e armazenar informações sobre Suas preferências ou Sua atividade
                            em
                            nosso Serviço. Os Flash Cookies não são gerenciados pelas mesmas configurações do navegador
                            usadas
                            para os cookies do navegador. Para obter mais informações sobre como excluir cookies Flash,
                            leia
                            "Onde posso alterar as configurações para desabilitar ou excluir objetos compartilhados
                            locais?"
                            disponível em <a
                                href=" https://helpx.adobe.com/flash-player/kb/disable-local-shared-objects-flash.html#main_Where_can_I_change_the_settings_for_disabling__or_deleting_local_shared_objects_">https://helpx.adobe.com/</a></p>
                        </li>.
                    <li>
                        <p><strong class="text-muted ">Web Beacons.</strong> Certas seções de nosso Serviço e nossos
                            e-mails podem conter pequenos arquivos
                            eletrônicos conhecidos como web beacons (também chamados de clear gifs, pixel tags e gifs de
                            pixel
                            único) que permitem que a Empresa, por exemplo, conte os usuários que visitaram essas
                            páginas ou
                            abriu um e-mail e outras estatísticas de sites relacionadas (por exemplo, registrando a
                            popularidade
                            de uma determinada seção e verificando a integridade do sistema e do servidor).</p></li>
                    <li>
                        <p><strong class="text-muted ">Os cookies podem ser Cookies "Persistentes" ou "Sessão".</strong>
                            Os cookies persistentes permanecem
                            em seu computador pessoal ou dispositivo móvel quando você fica offline, enquanto os cookies
                            de
                            sessão são excluídos assim que você fecha seu navegador. Saiba mais sobre cookies: Uso de
                            Cookies pela
                            Política de Privacidade Gratuita .</p>
                    </li>
                </ul>
                <p>Usamos Cookies de Sessão e Persistentes para os fins descritos abaixo:</p>
                <ul>
                    <li><p>Cookies Necessários / Essenciais</p></li>
                    <li><p>Tipo: Cookies de sessão</p></li>
                    <li><p>Administrado por: Nós</p></li>
                </ul>

                <p><strong class="text-muted ">Finalidade:</strong> Esses cookies são essenciais para fornecer a você os
                    serviços disponíveis no site e
                    permitir que você use alguns de seus recursos. Eles ajudam a autenticar usuários e evitar o uso
                    fraudulento de contas de usuários. Sem esses cookies, os serviços que você solicitou não podem ser
                    fornecidos, e nós usamos esses cookies apenas para fornecer esses serviços a você.</p>

                <ul>
                    <li><p>Política de Cookies / Cookies de Aceitação de Avisos</p></li>
                    <li><p>Tipo: Cookies persistentes</p></li>
                    <li><p>Administrado por: Nós</p></li>
                </ul>

                    <p><strong class="text-muted ">Finalidade:</strong> Esses Cookies identificam se os usuários
                        aceitaram o uso de cookies no Site.</p>

                <ul>
                    <li><p>Cookies de funcionalidade</p></li>
                    <li><p>Tipo: Cookies persistentes</p></li>
                    <li><p>Administrado por: Nós</p></li>
                </ul>

                    <p><strong class="text-muted ">Finalidade:</strong> Esses cookies nos permitem lembrar as escolhas
                        que você faz quando usa o site, como
                        lembrar seus detalhes de login ou preferência de idioma. O objetivo desses cookies é fornecer a
                        você uma experiência mais pessoal e evitar que você precise inserir novamente suas preferências toda
                        vez que usar o site.</p>

                    <p>Para obter mais informações sobre os cookies que usamos e suas escolhas em relação aos cookies,
                        visite nossa Política de Cookies ou a seção Cookies de nossa Política de Privacidade.</p>

                    <h2 class="card-title text-muted" id="coletados">Uso de seus dados pessoais</h2>
                    <p>Empresa pode usar Dados Pessoais para os seguintes fins:</p>

                    <p><strong class="text-muted ">Para fornecer e manter nosso Serviço,</strong> inclusive para
                        monitorar o uso de nosso Serviço.</p>

                    <p><strong class="text-muted ">Para gerenciar Sua Conta:</strong> para gerenciar Seu registro como
                        usuário do Serviço. Os Dados Pessoais que você fornece podem dar a Você acesso a diferentes funcionalidades do Serviço que estão
                        disponíveis para Você como usuário registrado.</p>

                    <p><strong class="text-muted ">Para a execução de um contrato:</strong> o desenvolvimento,
                        cumprimento e realização do contrato de compra
                        dos produtos, itens ou serviços que Você comprou ou de qualquer outro contrato conosco através
                        do Serviço.</p>

                    <p><strong class="text-muted ">Para entrar em contato com você:</strong> Para entrar em contato com
                        você por e-mail, telefone, SMS ou outras
                        formas equivalentes de comunicação eletrônica, como notificações push de um aplicativo móvel
                        sobre atualizações ou comunicações informativas relacionadas às funcionalidades, produtos ou serviços
                        contratados, incluindo as atualizações de segurança, quando necessário ou razoável para sua
                        implementação.</p>

                    <p><strong class="text-muted ">Fornecer a Você notícias,</strong> ofertas especiais e informações
                        gerais sobre outros bens, serviços e
                        eventos que oferecemos que são semelhantes àqueles que você já comprou ou perguntou, a menos que
                        você tenha optado por não receber tais informações.</p>

                    <p><strong class="text-muted ">Para gerenciar seus pedidos:</strong> Para atender e gerenciar seus
                        pedidos para nós.</p>

                    <p><strong class="text-muted ">Para transferências de negócios:</strong> Podemos usar suas
                        informações para avaliar ou conduzir uma fusão,
                        alienação, reestruturação, reorganização, dissolução ou outra venda ou transferência de alguns
                        ou de
                        todos os nossos ativos, seja em continuidade ou como parte de falência, liquidação, ou processo
                        semelhante, no qual os Dados Pessoais detidos por Nós sobre os usuários do nosso Serviço estão
                        entre
                        os ativos transferidos.</p>

                    <p><strong class="text-muted ">Para outros fins:</strong> Podemos usar suas informações para outros
                        fins, como análise de dados,
                        identificação de tendências de uso, determinação da eficácia de nossas campanhas promocionais e
                        para
                        avaliar e melhorar nosso Serviço, produtos, serviços, marketing e sua experiência.</p>

                    <h2 class="card-title text-muted" id="coletados">Podemos compartilhar suas informações pessoais nas seguintes situações:</h2>

                    <p><strong class="text-muted ">Com Prestadores de Serviços:</strong> Podemos compartilhar Suas
                        informações pessoais com Prestadores de
                        Serviços para monitorar e analisar o uso de nosso Serviço, para entrar em contato com Você.
                        Para transferências de negócios: Podemos compartilhar ou transferir Suas informações pessoais em
                        conexão com ou durante negociações de qualquer fusão, venda de ativos da Empresa, financiamento
                        ou
                        aquisição de todo ou parte de Nossos negócios para outra empresa.</p>

                    <p><strong class="text-muted ">Com Afiliados:</strong> Podemos compartilhar Suas informações com
                        Nossos afiliados, caso em que exigiremos
                        que esses afiliados honrem esta Política de Privacidade. As afiliadas incluem nossa empresa
                        controladora e quaisquer outras subsidiárias, parceiros de joint venture ou outras empresas que
                        controlamos ou que estão sob controle comum conosco.</p>

                    <p><strong class="text-muted ">Com parceiros de negócios:</strong> Podemos compartilhar suas
                        informações com nossos parceiros de negócios
                        para oferecer a você determinados produtos, serviços ou promoções.</p>

                    <p><strong class="text-muted ">Com outros usuários:</strong> quando você compartilha informações
                        pessoais ou interage de outra forma nas
                        áreas públicas com outros usuários, essas informações podem ser visualizadas por todos os
                        usuários e
                        podem ser distribuídas publicamente para fora. Se você interagir com outros usuários ou se
                        registrar
                        por meio de um serviço de mídia social de terceiros, seus contatos no serviço de mídia social de
                        terceiros poderão ver seu nome, perfil, fotos e descrição de sua atividade. Da mesma forma,
                        outros
                        usuários poderão visualizar descrições de sua atividade, comunicar-se com você e visualizar seu
                        perfil.</p>

                    <p><strong class="text-muted ">Com o seu consentimento :</strong> Podemos divulgar suas informações
                        pessoais para qualquer outro propósito
                        com o seu consentimento.</p>

                    <h2 class="card-title text-muted" id="coletados">Retenção de seus dados pessoais</h2>
                    <p>Empresa reterá Seus Dados Pessoais apenas pelo tempo necessário para os fins estabelecidos nesta
                        Política de Privacidade. Nós reteremos e usaremos Seus Dados Pessoais na medida necessária para
                        cumprir nossas obrigações legais (por exemplo, se formos obrigados a reter seus dados para
                        cumprir
                        as leis aplicáveis), resolver disputas e fazer cumprir nossos acordos e políticas legais.</p>

                    <p>A Empresa também reterá Dados de Uso para fins de análise interna. Os Dados de Uso são geralmente
                        retidos por um período de tempo mais curto, exceto quando esses dados são usados ​​para
                        fortalecer a
                        segurança ou melhorar a funcionalidade de Nosso Serviço, ou somos legalmente obrigados a reter
                        esses
                        dados por períodos de tempo mais longos.</p>

                    <p>Transferência de seus dados pessoais informações, incluindo Dados Pessoais, são processadas nos
                        escritórios operacionais da Empresa
                        e em quaisquer outros locais onde as partes envolvidas no processamento estejam localizadas.
                        Isso
                        significa que essas informações podem ser transferidas para – e mantidas em – computadores
                        localizados fora de Seu estado, província, país ou outra jurisdição governamental onde as leis
                        de
                        proteção de dados podem diferir daquelas de Sua jurisdição.</p>

                    <p>Seu consentimento para esta Política de Privacidade seguido de Seu envio de tais informações
                        representa Sua concordância com essa transferência.</p>

                    <p>A Empresa tomará todas as medidas razoavelmente necessárias para garantir que Seus dados sejam
                        tratados com segurança e de acordo com esta Política de Privacidade e nenhuma transferência de
                        Seus
                        Dados Pessoais ocorrerá para uma organização ou país, a menos que haja controles adequados,
                        incluindo a segurança de Seus dados e outras informações pessoais.</p>

                    <h2 class="card-title text-muted" id="coletados">Divulgação de seus dados pessoais</h2>
                    <p><strong class="text-muted ">Transações comerciais</strong>
                    <p>Se a Empresa estiver envolvida em uma fusão, aquisição ou venda de ativos, Seus Dados Pessoais
                        poderão ser transferidos. Forneceremos um aviso antes que Seus Dados Pessoais sejam transferidos
                        e
                        fiquem sujeitos a uma Política de Privacidade diferente.</p>

                    <p>Aplicação da lei sobre certas circunstâncias, a Empresa pode ser obrigada a divulgar Seus Dados
                        Pessoais se exigido por
                        lei ou em resposta a solicitações válidas de autoridades públicas (por exemplo, um tribunal ou
                        agência governamental).</p>

                    <h2 class="card-title text-muted" id="coletados">Outros requisitos legais</h2>
                    <p>A Empresa pode divulgar Seus Dados Pessoais acreditando de boa fé que tal ação é necessária para:
                    </p>
                    <ul>
                        <li><p>Cumprir uma obrigação legal</p></li>
                        <li><p>Proteger e defender os direitos ou propriedade da Empresa</p></li>
                        <li><p>Prevenir ou investigar possíveis irregularidades relacionadas ao Serviço</p></li>
                        <li><p>Proteger a segurança pessoal dos Usuários do Serviço ou do público</p></li>
                        <li><p>Proteja-se contra responsabilidade legal</p></li>
                        <li><p>Segurança de seus dados pessoais</p></li>
                    </ul>
                    <p> A segurança de seus dados pessoais é importante para nós, mas lembre-se de que nenhum método de
                        transmissão pela Internet ou método de armazenamento eletrônico é 100% seguro. Embora nos
                        esforcemos
                        para usar meios comercialmente aceitáveis ​​para proteger seus dados pessoais, não podemos
                        garantir
                        sua segurança absoluta.</p>

                    <h2 class="card-title text-muted" id="coletados">Privacidade infantil</h2>
                    <p>Nosso Serviço não se dirige a menores de 13 anos. Não coletamos intencionalmente informações de
                        identificação pessoal de menores de 13 anos. Se Você é pai ou responsável e está ciente de que
                        Seu
                        filho nos forneceu Dados Pessoais, por favor Contate-Nos. Se tomarmos conhecimento de que
                        coletamos
                        Dados Pessoais de menores de 13 anos sem verificação do consentimento dos pais, tomaremos
                        medidas
                        para remover essas informações de nossos servidores.</p>

                    <p>Se precisarmos de consentimento como base legal para processar Suas informações e Seu país exigir
                        o
                        consentimento de um dos pais, poderemos exigir o consentimento de seus pais antes de coletarmos
                        e
                        usarmos essas informações.</p>

                    <h2 class="card-title text-muted" id="coletados">Links para outros sites</h2>
                    <p>Nosso Serviço pode conter links para outros sites que não são operados por Nós. Se você clicar em
                        um
                        link de terceiros, será direcionado ao site desse terceiro. Recomendamos fortemente que você
                        revise
                        a Política de Privacidade de cada site que você visita.</p>

                    <p>Não temos controle e não assumimos responsabilidade pelo conteúdo, políticas de privacidade ou
                        práticas de quaisquer sites ou serviços de terceiros.</p>

                    <h2 class="card-title text-muted" id="coletados">Alterações a esta Política de Privacidade</h2>
                    <p>Podemos atualizar nossa Política de Privacidade de tempos em tempos. Notificaremos você sobre
                        quaisquer alterações publicando a nova Política de Privacidade nesta página.</p>

                    <p>Informaremos Você por e-mail e/ou um aviso em destaque em Nosso Serviço, antes que a alteração
                        entre
                        em vigor e atualize a data da "Última atualização" na parte superior desta Política de
                        Privacidade.</p>

                    <p>Você é aconselhado a revisar esta Política de Privacidade periodicamente para quaisquer
                        alterações.
                        As alterações a esta Política de Privacidade são efetivas quando publicadas nesta
                        página.necessitatibus ut beatae, rerum laudantium fugiat eum vitae.</p>
                    </p>
            </div>
        </div>
    </section>
        </section>
      </div>
    </>
  );
}
