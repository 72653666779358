import React from "react";
import Hero from "../section/Hero";
import WhatWeDo from "../section/WhatWeDo";
import HowWeDo from "../section/HowWeDo";
import ResearchRecent from "../section/ResearchRecent";
import Cta from "../section/Cta";
import BlogRecent from "../section/BlogRecent";
import OurService from "../section/OurService";
import OurProducts from "../section/OurProducts";


export default function Home() {
  return (
    <>
      <Hero />
      <WhatWeDo />
      <HowWeDo />
      <Cta />
      <OurService />
      <OurProducts />
     {/*  <section className="px-5 py-5 bg-light">
        <BlogRecent />
      </section> */}
     {/*  <ResearchRecent /> */}
    </>
  );
}
