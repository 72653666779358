import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ResearchCard from "./../cards/ResearchCard.js";

class ResearchPublished extends React.Component {
  constructor(props) {
    super(props);
    this.state = { itemRows: [], thumbnail: "", authorName: "", authors: "", citation_id: "" };
  }
  researchAPI=
    "https://nomade-research.herokuapp.com/researches";

  componentDidMount() {
    fetch(this.researchAPI)
      .then((res) => res.json())
      .then((data) => {
        
        this.setState();
        const itemRows = [];
        
        data.forEach((item, i) => {
          const row = Math.floor(i / 3);
          if (!itemRows[row]) itemRows[row] = [];
          itemRows[row].push(item);

        });

        this.setState({ itemRows: itemRows });
        console.log("item",itemRows);

      });
  }

  render() {
    return (
      <div className="mb-5 ">
        {this.state.itemRows.map((row, i) => (
          <Row key={i}  >
            {row.map((item, j) => (
              <div className="mb-2 mt-2" >
               {/* <Col key={j} lg="4" md="6" sm="12 " > */}
                <ResearchCard {...item} />
              {/* </Col> */}

              </div>
            ))}
          </Row>
        ))}
      </div>
    );
  }
}


export default ResearchPublished