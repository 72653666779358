import React from "react";
import imagem from "../../assets/img/WhatWeDo.jpg";
import "./../../assets/css/WhatWeDo.css";

export default function WhatWeDo() {
  return (
    <section class="py-5 bg-light d-flex" id="scroll-target">
      <div class="container px-5 my-5">
        <div class="row gx-5 align-items-center">
          <div class="col-lg-6" data-aos="fade-up" data-aos-delay="100">
            <center>
              {/* <img src={healt} alt="Healt" width={300} className="mb-5" /> */}
              <img
                class="img-fluid rounded mb-5 mb-lg-0"
                src={imagem}
                style={{height:"312px", width:"700px"}}
                alt="what we do"
              />
            </center>
          </div>
          <div class="col-lg-6" data-aos="fade-up" data-aos-delay="100">
            <h2 className="fw-bolder"> What we do </h2>
            <p class="fw-normal text-muted mt-15">
              {" "}
              We develop personalized digital solutions for the everyday
              workflows and experiences. We use our technological background to
              simplify your daily processes and help you focus on other areas of
              your business. Find out more about how we can help you bring your
              ideas into software.
            </p>
            {/*  <a class="btn-get-started" href="o-que-fazemos.html">
              {" "}
              Read More
              <i class="bi bi-arrow-right"></i>
            </a>  */}
          </div>
        </div>
      </div>
    </section>
  );
}
