import { Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import ClinicalHistory from "../../assets/img/Nomad-e_simbolo.png";
import FlipCard from "../cards/FlipCard";

function OurProducts() {
  return (
    <section className="px-5 py-5" style={{ backgroundColor: "#f5f5f5" }}>
      <center>
        <h1>Our Products</h1>
      </center>
      <div className="container">
        <Row className="justify-content-center">
          <Col lg="4" md="6" sm="12" className="mb-4">
            <FlipCard
              title="Universal Clinical History Certificate"
              img={ClinicalHistory}
              text="The clinical history is the set of documents that contains data, evaluations and information of any nature, about the situation and clinical evolution of a patient throughout the care process."
              link="#"
            />
          </Col>

          <Col lg="4" md="6" sm="12" className="mb-4">
            <FlipCard
              title="Pharmacy Assistant "
              img={ClinicalHistory}
              text="A first of its kind medication management tool. The Pharm-Assist helps healthcare professionals reconciliate their patient’s medication using certified databases.  "
              link="#"
            />
          </Col>
        </Row>
      </div>
    </section>
  );
}

export default OurProducts;
