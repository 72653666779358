import React from "react";
import { Row, Container } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import BlogCard from "./../cards/BlogCard.js";
import { Button } from "react-bootstrap";

class BlogRecent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { itemRows: [], avatar: "", profileLink: "" };
  }
  mediumURL =
    "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@nomad-e";

  componentDidMount() {
    fetch(this.mediumURL)
      .then((res) => res.json())
      .then((data) => {
        // create two-dimensional array with 3 elements per inner array
        const avatar = data.feed.image;
        const profileLink = data.feed.link;
        const res = data.items; //This is an array with the content. No feed, no info about author etc..
        const posts = res.slice(0, 3);

        this.setState({ avatar: avatar, profileLink: profileLink });
        const itemRows = [];
        posts.forEach((item, i) => {
          item["avatar"] = this.state.avatar; // push avatar inside the json
          item["profilelink"] = this.state.profileLink; // push profile link inside the JSON
          const row = 1;
          if (!itemRows[row]) itemRows[row] = [];
          itemRows[row].push(item);
        });
        this.setState({ itemRows: itemRows });

        // console.log(data.items);
      });
  }

  render() {
    return (
      <>
        <Container>
          <center className=" mb-5">
            <h1>Blog Recent Stories</h1>
          </center>
          <div className="row w-100" style={{ margin: "auto" }}>
            {this.state.itemRows.map((row, i) => (
              <Row key={i}>
                {row.map((item) => (
                  <Col lg="4" md="6" sm="12" className="mb-4">
                    <BlogCard {...item} />
                  </Col>
                ))}
              </Row>
            ))}
          </div>
          <div>
            <center>
              <Button variant="primary" href="/blog">
                All Stories
              </Button>{" "}
            </center>
          </div>
        </Container>
      </>
    );
  }
}

export default BlogRecent;
