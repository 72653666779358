import React from "react";
import Logo from "../../assets/img/Nomad-e_logo.png";
import "../../assets/css/Footer.css";
import { BsLinkedin, BsMedium } from "react-icons/bs";

export default function Footer() {
  return (
    <footer id="footer" class="footer">
      <div class="footer-content mb-0 pb-3">
        <div class="container ">
          <div class="row gy-5">
            <div class="col-lg-5 col-md-12 footer-info m-0 p-0">
              <a href="/" class="logo d-flex align-items-center ">
                <span>
                  <img src={Logo} alt="Nomad-e Logo" />
                </span>
              </a>
              {/* <p>
                We use AI, Blockchain Technology, and a Data Approach to empower
                the new digital generation.
              </p> */}
              <div class="social-links d-flex  mt-3 m-2">
                <a
                  target="_blank "
                  href="https://www.linkedin.com/company/nomad-e/"
                  class="linkedin"
                >
                  <BsLinkedin size={20} />
                </a>
                <a
                  target="_blank "
                  href="https://www.medium.com/@nomad-e"
                  class="medium"
                >
                  <BsMedium size={20} />
                </a>
              </div>
            </div>

            {/* Footer link */}
            <div class="col-lg-2 col-6 footer-links mt-1 mb-2">
              <h4>Website Road Map</h4>
              <ul>
                <li>
                  <i class="bi bi-dash"></i> <a href="/">Home</a>
                </li>
                {/* <li>
                  <i class="bi bi-dash"></i> <a href="aboutus">About us</a>
                </li> */}

                <li>
                  <i class="bi bi-dash"></i> <a href="blog">Blog</a>
                </li>
                {/* <li>
                  <i class="bi bi-dash"></i>
                  <a href="research">Research</a>
                </li> */}
                <li>
                  <i class="bi bi-dash"></i> <a href="contact">Contact</a>
                </li>
                <li>
                  <i class="bi bi-dash"></i>{" "}
                  <a target="_blank " href="/Privacy">
                    Terms of service
                  </a>
                </li>
                {/* <li>
                  <i class="bi bi-dash"></i> <a href="#">Privacy policy</a>
                </li> */}
              </ul>
            </div>
          </div>

          {/* copyright */}
          <div class="footer-legal m-0 p-0">
            <div class="container ">
              <div class="copyright ">
                Copyright &copy; <span>Nomad-e</span> 2022
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
