import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./components/pages/Home";
import AboutUs from "./components/pages/AboutUs";
import Contact from "./components/pages/Contact";
import Blog from "./components/pages/Blog";
import Research from "./components/pages/Research";
import Storie from "./components/pages/Storie.js";
import ResearchDetail from "./components/pages/ResearchDetail";
import Privacy from "./components/pages/Privacy";

const Rotas = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="aboutus" element={<AboutUs />} />
      <Route path="contact" element={<Contact />} />
      <Route path="blog" element={<Blog />} />
      <Route path="privacy" element={<Privacy />} />
      <Route path="blog/:guid" element={<Storie />} />
      <Route path="research" element={<Research />} />
      <Route path="research/:citation_id" element={<ResearchDetail />} />
    </Routes>
  );
};

export default Rotas;
