import React from "react";
import "./../../assets/css/HeroSection.css";

export default function WhatWeDo() {
  return (
    <section class="py-5  d-flex" id="scroll-target">
      <div class="container px-5 my-5 justify-content-center">
        <h1 class="fw-bolder ">
          {" "}
          We use AI, Blockchain Technology, and a Data Approach to empower the
          new digital generation.
        </h1>
      </div>
    </section>
  );
}
