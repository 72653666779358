import React, { useEffect } from "react";
import { Card, Container, Row, Col } from "react-bootstrap";
import ShortenText from "../../utils/ShortenText.js";
import ToText from "../../utils/ToText.js";
import { faUser, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../assets/css/Button.css";
import { useParams } from "react-router-dom";
import { GrDocumentPdf} from "react-icons/gr";

export default function ResearchDetailSection(props) {
  const citation_id = useParams();

  useEffect(() => {
    console.log("Citation Id", citation_id);

    console.log("props", props);
  });

  function hasValues(field) {
    if (field != (undefined || null)) {
      return true;
    }
    return false;
  }

  return (
    <Container className="mt-3">
      <Row>
        <Col>
          <h5>{props.title}</h5>
        </Col>
        <Col>
          {" "}
          <a href={props.resources[0].link}>
            <GrDocumentPdf />{" "} 
            {props.resources[0].title}{" "}
          </a>
        </Col>
      </Row>
      <br />
      {hasValues(props.authors) ? (
        <>
          <Row>
            <Col className="col-md-auto">Authors: </Col>
            <Col>{props.authors}</Col>
          </Row>
          <br />
        </>
      ) : (
        <></>
      )}
      {hasValues(props.publication_date) ? (
        <>
          <Row>
            <Col className="col-md-auto">Publication date: </Col>
            <Col>{props.publication_date}</Col>
          </Row>
          <br />
        </>
      ) : (
        <></>
      )}

      {hasValues(props.journal) ? (
        <>
          <Row>
            <Col className="col-md-auto">Publication: </Col>
            <Col>{props.journal}</Col>
          </Row>
          <br />
        </>
      ) : (
        <></>
      )}

      {hasValues(props.volume) ? (
        <>
          <Row>
            <Col className="col-md-auto">Volume: </Col>
            <Col>{props.volume}</Col>
          </Row>
          <br />
        </>
      ) : (
        <></>
      )}

      {hasValues(props.issue) ? (
        <>
          <Row>
            <Col className="col-md-auto">Issue: </Col>
            <Col>{props.issue}</Col>
          </Row>
          <br />
        </>
      ) : (
        <></>
      )}

      {hasValues(props.pages) ? (
        <>
          <Row>
            <Col className="col-md-auto">Pages: </Col>
            <Col>{props.pages}</Col>
          </Row>
          <br />
        </>
      ) : (
        <></>
      )}

      {hasValues(props.publisher) ? (
        <>
          <Row>
            <Col className="col-md-auto">Publisher: </Col>
            <Col>{props.publisher}</Col>
          </Row>
          <br />
        </>
      ) : (
        <></>
      )}

      {hasValues(props.description) ? (
        <>
          <Row>
            <Col className="col-md-auto">Description: </Col>
            <Col>{props.description}</Col>
          </Row>
          <br />
        </>
      ) : (
        <></>
      )}

      {hasValues(props.scholar_articles.versions) ||
      hasValues(props.scholar_articles.related_pages_link) ? (
        <>
          <Row className="col-md-auto mb-3">
            <Col>Articles Google Scholar </Col>
            <Col>
              <a href={props.scholar_articles[0].link}>
                {props.scholar_articles[0].title}
              </a>
              <br />
              {props.scholar_articles[0].authors}
              <br />
              {props.scholar_articles[0].versions ? (
                <a href={props.scholar_articles[0].versions.link}>
                  All {props.scholar_articles[0].versions.total} vesions
                </a>
              ) : (
                <></>
              )}

              {props.scholar_articles[0].related_pages_link ? (
                <a href={props.scholar_articles[0].related_pages_link.link}>
                  All related pages
                </a>
              ) : (
                <></>
              )}
            </Col>
          </Row>
          <br />
        </>
      ) : (
        <></>
      )}
    </Container>
  );
}
