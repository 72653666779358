import React from "react";
import OurService from "../section/OurService";
import OurProducts from "../section/OurProducts";

export default function AboutUs() {
  return (
    <><section class="px-5 py-4 mt-5">

      <OurService />
      <OurProducts />
    </section>
    </>
  );
}
