import React, { Component, useEffect, useState } from "react";
import "./../../assets/css/HeroSection.css";
/* import "./../../assets/css/Button.css"; */
import Button from "react-bootstrap/Button";
import logo from "./../../assets/img/Nomad-e_logo.png";
import Form from "react-bootstrap/Form";
/* import video from "../../assets/vid/pexels-rostislav-uzunov-7513671.mp4"; */
import video from "../../assets/vid/nomade-hero-v.mp4";
import Alert from 'react-bootstrap/Alert';
import Spinner from 'react-bootstrap/Spinner';

export default function Hero() {
  // Text Animation
  const animation = () => {
    /*  const text = document.querySelector(".sec-text"); */
    const text = document.getElementById("animation");
    console.log("imprimendo o text para aniamacao  ", text);
    const textLoad = () => {
      setTimeout(() => {
        text.textContent = "Your";
      }, 0);

      setTimeout(() => {
        text.textContent = "Personal";
      }, 3000);

      setTimeout(() => {
        text.textContent = "Tech";
      }, 6000);

      setTimeout(() => {
        text.textContent = "Solution";
      }, 9000);
    };

    textLoad();
    setInterval(textLoad, 16000);
  }

  useEffect(() => {
    animation();
  }, [])

  // Form Submit
  const [inputs, setInputs] = useState({});
  const [email, setEmail] = useState(undefined);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleChange = (event) => {
    // const name = event.target.name;
    // const value = event.target.value;
    // setInputs(values => ({ ...values, [name]: value }))
  }
  
  const handleSubmit = async (event) => {
    event.preventDefault();
    try{
    const data = new URLSearchParams();
    const formElement = document.querySelector("#form-data")
    for (const pair of new FormData(formElement)) {
        data.append(pair[0],pair[1]);
    }
    const url = 'https://crm.zoho.com/crm/WebToLeadForm';
    const response = await fetch(url, { method: 'POST', body: data });
    if(response.status >= 200 && response.status <=399){
      setLoading(false)
      setEmail("")
      setSuccess(true)
      setTimeout(()=>setSuccess(false), 6000)
    }else{
      console.log("ERROR STATUS: ",response.status)
      setError(true)
    }

  }catch(err){
    console.log(err)
    setLoading(false)
    setEmail("")
    setError(true)
    setTimeout(()=>setError(false), 3000)
  }
  }

  return (
 
    <div className="hero-container mt-5 pt-5 ">
      <video src={video} autoPlay loop muted />
      <img
        className="mt-5 pt-2"
        src={logo}
        id="hero-logo"
        alt="Nomad-e Empowering Humans"
        height="400px"
      />
      <section class="py-5 w-100 m-5">
        <div class="container px-5 ">
          <div class=" justify-content-start w-100 ">
            <div class="" data-aos="fade-up" data-aos-delay="100">
              <div class="container-text">
                <h1>
                  <span class="text first-text fw-bolder mb-3">We are </span>
                  <p id="animation" class=".sec-text"></p>
                </h1>
                {/* <p>
                    We are experts in software development. We have experience
                    in the national and international market, we work in the
                    design, development and consulting of applications,
                    platforms and systems. Design, agility and technology allow
                    us to support innovation, the digital transformation of
                    companies, and assist startups and scale-ups in building and
                    sustaining their digital products and services.
                  </p> */}
                <form id="form-data" onSubmit={handleSubmit}>
                  {/* <div className="hero-btns mb-5 mt-5 "> */}
                   {/*  <input type="email" class="form-control w-50 md-4" name="email" id="email" placeholder="Your Email " required onChange={handleChange} /> */}
                    <input type='text' style={{display:"none"}} name='xnQsjsdp' id="xnQsjsdp" value='dd1540683c2ab91fdf828a3f9d381acd8a834197a2ee4db1307c2dcda5f0afb7' /> 
                  <input type='hidden' name='zc_gad' id='zc_gad' value=''></input> 
                  <input  input type='text' style={{display:"none"}} name='xmIwtLD' id="xmIwtLD" value='8b8f4b87d121501770423c3d6ec5f81550dc52d5fb5a07efc35408ce74ff968b' />
                  <input type='text'  style={{display:"none"}} name='actionType' id='actionType' value='TGVhZHM=' />
                  <input type='text' style={{display:"none"}} name='returnURL' id='returnURL' value='https&#x3a;&#x2f;&#x2f;nomad-e.eu&#x2f;' /> 
                    
                  <input type="text" name="Last Name" class="form-control" id="Last Name" placeholder="Your Name " style={{display:"none"}} value="hero subscription" required onChange={handleChange} />
            
                  <div className="hero-btns mb-5 mt-5 w-auto">
                    <input type="email" className="form-control w-50 md-4" name="Email" id="Email" placeholder="Your Email " required onChange={handleChange} readOnly={false} value={email} defaultValue={email}  />
                    <div class="text-center">
                      {!loading
                      ?<button className="button mt-0 mb-0 m-4 text-center" type="submit">SUBSCRIBE</button>
                      :<Button variant="primary mt-0 mb-0 m-4" disabled>
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      Loading...
                    </Button>}
                    </div>
                  </div>
                  <div className="hero-btns mb-5 mt-5">
                  {success 
                    ? <Alert className="form-control w-50 md-4" variant="info" onClose={() => setSuccess(false)} dismissible>Thank you for subscribing, you will receive a confirmation email from us shortly.</Alert>
                    : <></>
                  }
                  {error 
                  ?<Alert variant="warning" onClose={() => setError(false)} dismissible>Oups! An error occured. Appologies for the inconvenience. Please try again.</Alert>
                  :<></>
                }
                </div>
                </form>
              </div>
            </div>

          </div>
        </div>
      </section >
    </div>
  );
}

