import React from "react";
import ResearchPublished from "../section/ResearchPublished.js";
import HeroResearch from "../section/HeroResearch.js";


export default function Research() {
  return (
    <>
    <div>

          <HeroResearch />
      <div className="container ">
        
        <section className="justify-content-end ">
          <br />
        
          <h1>Publications</h1>
         
            <ResearchPublished />
        </section>

      </div>
    </div>
    </>
  );
}
