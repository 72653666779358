import React from "react";
import { Card, Container } from "react-bootstrap";
import ShortenText from "../../utils/ShortenText.js";
import ToText from "../../utils/ToText.js";
import { faUser, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../assets/css/Button.css";
import NotFoundImg from "../../assets/img/Nomad-e_NotFound-Image-Transparent.png";

export default function StorieDetails(props) {
  /* "title"
    "pubDate 
    "author"
    "thumbnail"
    "description"
     "content"
    */
  function selectImage(thumb) {
    var tam = thumb.length;
    if (
      thumb.slice(tam - 4, tam) === ".jpg" ||
      thumb.slice(tam - 4, tam) === ".png" ||
      thumb.slice(tam - 5, tam) === ".jpeg"
    ) {
      return <Card.Img variant="top" src={props.thumbnail} />;
    } else {
      return <Card.Img variant="top" src={NotFoundImg} />;
    }
  }

  var shortMonthName = new Intl.DateTimeFormat("en-US", {
    month: "short",
  }).format;
  let date = new Date(props.pubDate);
  const publishDate =
    shortMonthName(date) +
    " " +
    date.getDate() +
    "," +
    " " +
    date.getFullYear();
  return (
    <Container>
      <div className="row w-75 mt-3" style={{ margin: "auto" }}>
        <h2 className="fw-bold">
          {ShortenText(props.title, 0, props.title.length)}
        </h2>
        <div className="d-flex justify-content-between mb-3">
          <span className="text-dark">
            <FontAwesomeIcon icon={faUser} /> {props.author}
          </span>
          <br />

          <span className="text-muted">
            <FontAwesomeIcon icon={faCalendarAlt} /> {publishDate}
          </span>
        </div>

        <p className="card-text d-inline-block mb-3">
          {ShortenText(ToText(props.content), 0, props.content.length)}
        </p>
        <br />
        {selectImage(props.thumbnail)}

        <br />
      </div>
    </Container>
  );
}
