import React from "react";
import Card from "react-bootstrap/Card";
import ShortenText from "../../utils/ShortenText.js";
import ToText from "../../utils/ToText.js";
import { faUser, faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from "react-bootstrap";
import "../../assets/css/Button.css";
import NotFoundImg from "../../assets/img/Nomad-e_NotFound-Image-Transparent.png";
import { useNavigate } from "react-router-dom";

export default function BlogCard(props) {
  const navigate = useNavigate();

  function blogDetail(guid) {
    //https://medium.com/p/
    //21 - length

    const url = guid.slice(21, guid.length);
    navigate(`/blog/${url}`);
  }

  // console.log(props.guid.slice(21, props.guid.length));

  function selectImage(thumb) {
    var tam = thumb.length;
    if (
      thumb.slice(tam - 4, tam) === ".jpg" ||
      thumb.slice(tam - 4, tam) === ".png" ||
      thumb.slice(tam - 5, tam) === ".jpeg"
    ) {
      return (
        <Card.Img
          variant="top"
          src={props.thumbnail}
          style={{ height: "10rem" }}
        />
      );
    } else {
      return <Card.Img variant="top" src={NotFoundImg} />;
    }
  }

  var shortMonthName = new Intl.DateTimeFormat("en-US", {
    month: "short",
  }).format;
  let date = new Date(props.pubDate);
  const publishDate =
    shortMonthName(date) +
    " " +
    date.getDate() +
    "," +
    " " +
    date.getFullYear();
  return (
    <Card
      small
      className="card-post card-post--1"
      onClick={() => blogDetail(props.guid)}
    >
      {selectImage(props.thumbnail)}

      {/*  <div className="card-post__author d-flex">
          <a
            href={props.profilelink}
            target="_blank"
            className="card-post__author-avatar card-post__author-avatar--small"
            style={{
              backgroundImage: `url(${props.avatar})`
            }}
            rel="noopener noreferrer"
          >
            Written by {props.author}
          </a>
        </div> */}

      <Card.Body className="row">
        <h5 className="card-title">
          <Button
            variant="link"
            className="p-0"
            size="lg"
            onClick={() => blogDetail(props.guid)}
          >
            {props.title.length > 35
              ? ShortenText(ToText(props.title), 0, 35) + "..."
              : ShortenText(props.title, 0, 38)}
          </Button>
        </h5>
        <p className="card-text d-inline-block mb-3 text-truncate">
          {ShortenText(ToText(props.content), 0, 120) + "..."}
        </p>
        <br />
        <span className="text-dark">
          <FontAwesomeIcon icon={faUser} /> {props.author}
        </span>
        <br />
        <span className="text-muted">
          <FontAwesomeIcon icon={faCalendarAlt} /> {publishDate}
        </span>
        <br />
        <br />
      </Card.Body>
    </Card>
  );
}
