import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Footer from "./components/partials/Footer";
import Navbar from "./components/partials/Navbar";
import Rotas from "./routes";

function App() {
  return (
    <>
      <Navbar />
      <Router>
        <Rotas />
      </Router>
      <Footer />
    </>
  );
}

export default App;
